export const Colors = {
  kFF0000: '#FF0000',
  k0094DD: '#0094dd',
  kFFFFFF: 'FFFFFF',
  kF45D81: '#F45D81',
  k1F448C: '1F448C',
  tshirt: [''],
  hoodie: [],
  tanktop: [],
  tshirtkid: [],
  sweatshirt: [],
  poloshirt: [],
};

export const BG_COLORS = [
  '#aca69f',
  '#9bb2df',
  '#e49d6a',
  '#ab9494',
  '#abb89b',
  '#737373',
  '#79ae8e',
  '#a8a086',
  '#e49d6a',
  '#aca69f',
  '#e49d6a',
];

export const BG_COLOR_NAMES = {
  kBAC0D6: 'ashgrey',
  k3C4144: 'charcoal',
  kF8AF0A: 'gold',
  k1E3D01: 'irishgreen',
  k00021A: 'navy',
  k29074D: 'purple',
  kFFFFFF: 'white',
  k000000: 'black',
  k9AB9D4: 'lightblue',
  kA90519: 'red',
  k5E82C7: 'carolinablue',
  kA7073E: 'heliconia',
  kEFB9C9: 'lightpink',
  kE53F04: 'orange',
  k1F448C: 'royal',
  k332822: 'brown',
  kE2E2E2: 'icegrey',
  kE46DA6: 'azalea',
  k3C050D: 'drakred',
  k112509: 'forestgreen',
  k23733F: 'kellygreen',
  k4D4D37: 'mitarygreen',
  k565758: 'smokegrey',
  kE3D9C3: 'natural',
  kF33F72: 'safetypink',
};
